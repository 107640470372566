import { Global } from '@emotion/core'
import { APP_HOME_PATH } from '@guess-the-rose/core'
import {
  Box,
  Heading,
  Image,
  Link,
  Section,
  Text,
  useTheme,
} from '@guess-the-rose/web-shared'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import React from 'react'

import { FirebaseAuth, Layout } from '../components'
import { useAuth } from '../context/useAuth'

export default function LoginPage() {
  const auth = useAuth()
  const theme = useTheme()
  const router = useRouter()

  if (auth.isAuthed) {
    router.push(APP_HOME_PATH)
  }

  return (
    <Layout
      containerProps={{
        height: [
          `calc(100vh - ${theme.dimensions.mobileNavHeight})`,
          null,
          `calc(100vh - ${theme.dimensions.desktopNavHeight})`,
        ],
        overflow: 'hidden',
      }}
    >
      <NextSeo title="Login | Guess the Rose" />
      <Global
        styles={{
          '.firebaseui-card-header': {
            display: 'none',
          },
          '.firebaseui-title': {
            display: 'none',
          },
          '.firebaseui-id-submit': {
            color: 'white',
            background: `${theme.colors.brand.primary} !important`,
          },
          '.firebaseui-id-secondary-link': {
            color: `${theme.colors.brand.textColor} !important`,
          },
          '.firebaseui-id-page-sign-in': {
            paddingTop: '1rem',
          },
          '.firebaseui-container.mdl-shadow--2dp': {
            boxShadow: 'none',
          },
        }}
      />
      <Box
        alignItems={[null, null, 'center']}
        display={[null, null, 'flex']}
        height={'100%'}
        justifyContent={[null, null, 'center']}
      >
        <Section
          innerContainerProps={{
            maxWidth: ['330px', null, 'inherit'],
            width: [null, null, '500px', '600px'],
            background: 'white',
            borderRadius: 'md',
            border: '1px solid',
            borderColor: 'gray.100',
            p: '4',
            mt: ['8', null, 0],
            flexShrink: 0,
            position: 'relative',
          }}
          outerContainerProps={{
            px: 0,
            py: 0,
          }}
        >
          <Box margin="0 auto" maxWidth="320px" mb="16">
            <Heading as="h1" mb="8" mt={['6']} textAlign="center">
              Login or Register to Get Started
            </Heading>
            <FirebaseAuth />
          </Box>
          <Box margin="0 auto" maxWidth="320px">
            <Text fontSize={['xs', null, 'sm']} mb="4" textAlign="center">
              By creating an account, you agree to our{' '}
              <Link href="/terms-and-conditions" textDecoration="underline">
                terms and conditions
              </Link>{' '}
              and{' '}
              <Link href="/privacy-policy" textDecoration="underline">
                privacy policy
              </Link>
              .{' '}
            </Text>
            <Text fontSize={['xs', null, 'sm']} textAlign="center">
              We are not affiliated, associated, authorized, endorsed by, or in
              any way officially connected with ABC, The Bachelor, or any of its
              subsidiaries or its affiliates.
            </Text>
          </Box>
          <Image
            display={['none', null, 'block']}
            left="-30%"
            position="absolute"
            // From blog package cause next.js public folder broken
            src={'/images/roseLeft.png'}
            top="50%"
            transform="translateY(-50%)"
            zIndex={-1}
          />
          <Image
            display={['none', null, 'block']}
            position="absolute"
            right="-20%"
            // From blog package cause next.js public folder broken
            src={'/images/roseRight.png'}
            top="50%"
            transform="translateY(-50%)"
            zIndex={-1}
          />
          <Image
            bottom="-30%"
            display={['none', null, 'block']}
            left="50%"
            position="absolute"
            src={'/images/roseRight.png'}
            transform="translateX(-50%) rotate(90deg)"
            zIndex={-1}
          />
          <Image
            display={['none', null, 'block']}
            left="50%"
            position="absolute"
            src={'/images/roseRight.png'}
            top="-30%"
            transform="translateX(-50%) rotate(-90deg)"
            zIndex={-1}
          />
        </Section>
      </Box>
    </Layout>
  )
}
